/* App.css */
.truncate-container {
  align-items: center;
  justify-content: space-between;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.truncate-text {
  font-size: 2.2rem;
  flex: 1;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.copy-icon {
  margin-left: 10px;
  cursor: pointer;
  color: #9b7127;
  transition: color 0.3s ease;
}

.copy-icon:hover {
  color: #76500f;
}

.copied-confirmation {
  font-size: 1.2rem;
  color: #9b7127;
  margin-left: 10px;
}

.copied-message {
  /* position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%); */
  /* background-color: #9b7127; */
  color: #9b7127;
  width: 100%;
  /* padding: 10px 20px; */
  border-radius: 8px;
  font-size: 18px;
  z-index: 9999;
  opacity: 1;
  transition: opacity 0.3s ease-in-out;
}

.copied-message-hide {
  opacity: 0;
}
