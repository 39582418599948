/* src/App.css */
:root {
  --primary: #9b7127;
  --textColor: var(--primary);
}
/* Global styles */
body {
  margin: 0;
  font-family: "Comic Sans", "Comic Sans MS", "Chalkboard", "ChalkboardSE-Regular", cursive, sans-serif;
  background-color: #fffdf9;
  /* background-color: #f3f4f6;  */
  color: var(--primary); /* Main professional blue */
}

.App {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  min-height: 100vh;
  margin: 0 50px;
  animation: fadeIn 1s ease-in-out; /* Fade-in animation */
}

.header {
  text-align: center;
  padding: 20px;
  /* background-color: #EBC9A3; */
  color: var(--primary);
  width: 100%;

  animation: slideIn 1s ease-in-out; /* Slide-in animation */
}

.header h1 {
  font-size: 3rem;
  margin: 0;
}

.about,
.gallery,
.cta,
footer {
  text-align: center;
  padding: 20px;
  width: 100%;
  margin-top: 50px;
}

.about {
  animation: fadeIn 1.5s ease-in-out; /* Fading effect */
}

.about p {
  padding-left: 20%;
  padding-right: 20%;
  font-size: 1.5rem;
}
.about b {
  font-weight: 600;
}

/* Updated Gallery Section Styles */

.gallery {
  padding: 20px 20px; /* Add padding to top and bottom, and a bit to the sides */
}

.image-gallery {
  display: flex; /* Use flexbox to align items in a row */
  justify-content: center; /* Center the items horizontally */
  flex-wrap: wrap; /* Allow wrapping for smaller screens */
  gap: 10px; /* Space between images */
}

.image-container {
  width: 300px; /* Fixed width for each image container */
  height: 300px; /* Fixed height for each image container */
  overflow: hidden; /* Prevent overflow */
  border-radius: 10px; /* Rounded corners */
  display: flex; /* Center content */
  align-items: center; /* Center content vertically */
  justify-content: center; /* Center content horizontally */
}

.image-gallery img {
  width: 100%;
  height: 100%;
  object-fit: cover; /* Maintain aspect ratio and cover the container */
  transition: transform 0.3s ease-in-out; /* Animation for hover effect */
}

.image-gallery img:hover {
  transform: scale(1.05); /* Zoom in slightly on hover */
}

button {
  background-color: var(--primary); /* Lighter blue for buttons */
  color: white;
  border: none;
  padding: 24px 48px;
  margin: 20px;
  cursor: pointer;
  font-size: 18px;
  border-radius: 100px;
  font-weight: bold;
  text-transform: uppercase;
  border: 1px solid #fff;
  transition: all 0.1s ease;
}

button:hover {
  transform: scale(1.2);
  transition: all 0.1s ease;
  background-color: #774e08; /* Darker blue on hover */
}

footer {
  /* background-color: #EBC9A3; */
  color: var(--primary);
  padding: 20px;
  width: 100%;
  animation: slideIn 1s ease-in-out; /* Slide-in animation */
}

footer ul {
  list-style: none;
  padding: 0;
  display: flex;
  justify-content: center;
}

footer li {
  margin: 0 15px;
}

footer a {
  color: var(--primary);
  text-decoration: none;
  font-weight: bold;
}

.head-text {
  font-size: 3rem;
}

.signature {
  font-size: 2rem;
}

/* Animations */
@keyframes fadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

@keyframes slideIn {
  0% {
    transform: translateY(-50px);
    opacity: 0;
  }
  100% {
    transform: translateY(0);
    opacity: 1;
  }
}

/* Responsive styles */
@media (max-width: 768px) {
  .image-gallery img {
    max-width: 100%;
  }

  button {
    width: 90%;
    font-size: 16px;
  }

  .App {
    margin: 0 20px;
  }

  .about p {
    padding-left: 10%;
    padding-right: 10%;
  }
}
footer a {
  padding-top: 20px;
  font-size: 2rem;
  /* text-decoration: underline; */
}
footer a:hover {
  text-decoration: underline;
}

.dogebite-img {
  width: 200px;
  height: 200px;
}
